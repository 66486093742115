import { Button, Heading4 } from 'components';
import { axiosGet, axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import {
  ILastPaymentSession,
  OperatorSessionsGroup,
  ParkingSessionsApiResponse,
  UnCompletedPaymentOrderShape,
} from 'interfaces';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcons } from 'shared';
import { Alignment } from 'static';
import './_operator-parking-sessions.scss';
import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router';
import CarParkGroup from 'components/parking-details/car-park';
import { dispatch } from 'redux/store';
import { setParkingSessionsResponse } from 'redux/parking-sessions';

interface Props {
  data: OperatorSessionsGroup;
  isUnCompletePaymentOrder?: boolean;
}

const OperatorParkingSessions: FC<Props> = ({
  data,
  isUnCompletePaymentOrder,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const listDivRef = useRef<HTMLDivElement>(null);
  const [showPayButtonDetails, setShowPayButtonDetails] = useState(false);
  const { search } = useLocation();
  const regNo = new URLSearchParams(search).get('regNo');

  const [operatorDetails, setOperatorDetails] =
    useState<UnCompletedPaymentOrderShape>();

  useEffect(() => {
    const selfLink = getApiHrefByRel(data.Links, 'self');
    if (selfLink) {
      axiosGet<UnCompletedPaymentOrderShape>(selfLink).then((response) => {
        if (response && response?.status === 200) {
          setOperatorDetails(response.data);
        }
      });
    }
  }, [data.Links]);

  const handlePayment = async (operator: OperatorSessionsGroup) => {
    const payLink = getApiHrefByRel(operator.Links, 'pay');
    const { href, pathname } = window.location;

    const paymentPayload = {
      CompleteUrl: '/payment-complete',
      CancelUrl: `${
        pathname + href.substring(href.indexOf('?'))
      }&canceled=true`,
      TermsOfServiceUrl: `terms-and-conditions?operatorguid=${operator.OperatorGuid}`,
    };

    setIsLoading(true);
    try {
      if (!payLink) {
        throw Error(
          'Could not continue with handle payment because pay link is not available',
        );
      }
      const response = await axiosPost<ParkingSessionsApiResponse>(
        payLink,
        paymentPayload,
      );

      if (response?.data) {
        const { Items, Links } = response.data;

        if (!!Items && Items.length > 0) {
          dispatch(setParkingSessionsResponse(response.data));
        } else {
          const lastPaymentSessionData: ILastPaymentSession = {
            LicensePlate: regNo ?? '',
            OperatorName: operator.Name,
            TotalParkingFee: operator.TotalParkingFee,
            RequestReceiptLink:
              Links.find((link) => link.Rel === 'request-receipt')?.Href ?? '',
          };

          window.localStorage.setItem(
            'last_payment_session_data',
            JSON.stringify(lastPaymentSessionData),
          );

          const paymentGateWayLink = getApiHrefByRel(Links, 'pay');

          if (paymentGateWayLink) {
            window.location.href = paymentGateWayLink;
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleUnCompletedPayment = async () => {
    if (operatorDetails) {
      const paymentGateWayLink = getApiHrefByRel(operatorDetails.Links, 'pay');

      setIsLoading(true);

      const lastPaymentSessionData: ILastPaymentSession = {
        LicensePlate: regNo ?? '',
        OperatorName: operatorDetails.OperatorName,
        TotalParkingFee: operatorDetails.TotalAmount,
        RequestReceiptLink:
          operatorDetails.Links.find((link) => link.Rel === 'request-receipt')
            ?.Href ?? '',
      };
      window.localStorage.setItem(
        'last_payment_session_data',
        JSON.stringify(lastPaymentSessionData),
      );

      if (paymentGateWayLink) {
        window.location.href = paymentGateWayLink;
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setShowPayButtonDetails(entry.isIntersecting);
      },
      { threshold: 0.7 },
    );
    if (listDivRef.current) {
      observer.observe(listDivRef.current);
    }
  }, []);

  return (
    <div className="operator-parking-sessions">
      <Heading4 className="operator-name">{data.Name}</Heading4>

      <div ref={listDivRef}>
        {data.CarParks.map((carPark) => (
          <CarParkGroup data={carPark} key={carPark.CarParkGuid} />
        ))}
      </div>

      {showPayButtonDetails && (
        <div className="sticky-pay-bar">
          <Heading4 className="payment-total">
            {t('Total (Incl. Tax)')}{' '}
            <span className="heading4">{data.TotalParkingFee.TotalAmount}</span>
          </Heading4>
          <br />
          <Button
            disabled={isLoading}
            loading={isLoading}
            formatClassName="pay-button"
            onClick={() =>
              isUnCompletePaymentOrder
                ? handleUnCompletedPayment()
                : handlePayment(data)
            }
            iconName={SvgIcons.ArrowRight}
            iconPosition={Alignment.Right}
            iconWidth={25}
          >
            {t(isUnCompletePaymentOrder ? 'Complete payment' : 'Pay')}{' '}
            {isUnCompletePaymentOrder}
          </Button>
        </div>
      )}
    </div>
  );
};

export default OperatorParkingSessions;
