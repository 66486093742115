import { FC, useEffect, useState } from 'react';
import {
  Button,
  Form,
  InputSelect,
  InputText,
  Loader,
  Page,
  PageHeading,
} from 'components';
import { useForm, Controller } from 'react-hook-form';
import { axiosPost } from 'helpers';
import './AddVehicleForm.scss';
import Notification from 'components/notification';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatusType } from 'pages/request-reset-password/RequestPasswordResetPage';
interface AddVehicleInput {
  LicensePlate: string;
  Description: string;
  Country: string;
}

interface ErrorShapeData {
  Message: string;
  Errors: {
    LicensePlate: string[];
    Description: string[];
  };
}
interface ErrorShape {
  data: ErrorShapeData;
  status: number;
  statusText: '';
}
type LinkResponse = [
  {
    Rel: string;
    Href: string;
  },
];

export type AddVehicleApiResponse = {
  data: {
    Links: LinkResponse;
  };
  status: number;
  statusText: string;
};
type Props = {
  link: string;
  status: (status: StatusType) => void;
  onClose: () => void;
  callback: () => void;
};

const AddVehicleForm: FC<Props> = ({
  link: addVehicleLink,
  status,
  onClose,
  callback,
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<AddVehicleInput>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorShape | null>(null);

  const onSubmit = async (data: AddVehicleInput) => {
    try {
      if (!addVehicleLink) {
        return;
      }
      setLoading(true);
      setError(null);
      const payload = {
        LicensePlate: `${data.LicensePlate} (${data.Country})`,
        Description: data.Description,
      };

      const res = (await axiosPost(
        addVehicleLink,
        payload,
      )) as AddVehicleApiResponse;
      if (res.status === 200) {
        onClose();
        status({
          severity: 'success',
          message: t('Vehicle added successfully'),
        });

        callback();
      }
    } catch (error) {
      const errorObj = error as ErrorShape;
      if (errorObj.status === 400) {
        setError(errorObj);
      }
    } finally {
      setLoading(false);
    }
  };
  const countryOptions = [
    { value: 'N', label: 'N' },
    { value: 'S', label: 'S' },
    { value: 'DK', label: 'DK' },
    { value: 'FIN', label: 'FIN' },
  ];
  useEffect(() => {
    setValue('Country', 'N');
  }, [setValue]);

  return (
    <Page>
      <PageHeading heading="Add Vehicle" />

      {error && (
        <>
          {error.data?.Errors?.LicensePlate && (
            <Notification
              isAutoClosing={true}
              onClose={() => {
                setError(null);
              }}
              severity="error"
            >
              {error.data?.Errors?.LicensePlate[0]}
            </Notification>
          )}
          {error.data?.Errors?.Description && (
            <Notification
              isAutoClosing={true}
              onClose={() => {
                setError(null);
              }}
              severity="error"
            >
              {error.data?.Errors?.Description[0]}
            </Notification>
          )}
        </>
      )}

      <Form onSubmit={handleSubmit(onSubmit)} className="add-vehicle-form">
        <Grid container>
          <Grid
            item
            xs={3}
            sx={{ display: 'flex', alignItems: 'end', paddingRight: '10px' }}
          >
            <InputSelect
              name="Country"
              label={t('Country')}
              type="text"
              control={control}
              defaultValue={'N'}
              options={countryOptions}
              className="country-selector"
              rules={{
                required: t('Country field is required'),
              }}
              required
            />
          </Grid>
          <Grid item xs={9}>
            {' '}
            <Controller
              name="LicensePlate"
              control={control}
              defaultValue=""
              render={() => (
                <InputText
                  label={t('License plate')}
                  type="text"
                  control={control}
                  name="LicensePlate"
                  required
                  rules={{
                    required: t('Please enter License plate'),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          name="Description"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label={t('Description')}
              type="text"
              control={control}
              required
              name="Description"
              rules={{
                required: t('Please enter description'),
                maxLength: {
                  value: 15,
                  message: t('Description cannot exceed 15 characters'),
                },
              }}
            />
          )}
        />
        <Button type="submit" loading={loading} disabled={loading}>
          {loading ? <Loader loading={loading}></Loader> : t('Submit')}
        </Button>
      </Form>
    </Page>
  );
};

export default AddVehicleForm;
