import useLinksQuery from '_api/Links';
import { Button, Loader, Page, PageHeading } from 'components';
import VehicleCard from 'components/vehicle/VehicleCard';
import { axiosGet } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { parseTemplate } from 'url-template';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { VehicleListResponseData, Vehicle } from './types';
import { AlertColor, Grid } from '@mui/material';

import { StatusType } from 'pages/request-reset-password/RequestPasswordResetPage';
import Notification from 'components/notification';
import { t } from 'i18next';

import './VehicleList.scss';

import AddVehicleModal from './add';
import EditVehicleModal from './edit';

const VehicleList: FC = () => {
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);

  const [addVehicleLink, setAddVehicleLink] = useState<undefined | string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: endpoints } = useLinksQuery();
  const [showAddModal, setShowAddModal] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState<undefined | Vehicle>();
  const [status, setStatus] = useState<StatusType | null>(null);
  const [nextPageLink, setNextPageLink] = useState<undefined | string>();

  const vehiclesLink = useMemo(
    () => getApiHrefByRel(endpoints ?? [], 'vehicles'),
    [endpoints],
  );

  const getLinkForPage = (template: string, page = 0) => {
    const urlTemplate = parseTemplate(decodeURI(template));
    const link = urlTemplate.expand({
      pagesize: 20,
      page,
    });

    return link;
  };

  const getInitialVehiclesFromApi = useCallback(async () => {
    try {
      if (vehiclesLink) {
        const apiLink = getLinkForPage(vehiclesLink, 0);
        setIsLoading(true);
        const response = await axiosGet<VehicleListResponseData>(apiLink);
        if (!response) {
          throw Error('Got error response when trying to get vehicles');
        }
        console.log('response for getAllVehicles', response);
        const { Items, Links } = response.data;

        setNextPageLink(getApiHrefByRel(Links ?? [], 'next'));
        setAddVehicleLink(getApiHrefByRel(Links ?? [], 'add-vehicle'));

        setVehicleList(Items ?? []);
      }
    } catch (error) {
      console.error('Error while getting vehicles', error);
      setStatus({
        severity: 'error',
        message: 'Error while getting vehicles',
      });
    } finally {
      setIsLoading(false);
    }
  }, [vehiclesLink]);

  useEffect(() => {
    getInitialVehiclesFromApi();
  }, [getInitialVehiclesFromApi]);

  const loadNextPage = async () => {
    if (!nextPageLink) {
      console.error(
        'tried to load next page but could not, becuase there is no nextPageLink',
      );
      return;
    }
    try {
      const response = await axiosGet<VehicleListResponseData>(nextPageLink);
      if (!response) {
        throw Error('load next page get requrned error response');
      }
      const { Items, Links } = response.data;
      setVehicleList((prev) => [...prev, ...Items]);
      setNextPageLink(getApiHrefByRel(Links ?? [], 'next'));
    } catch (error) {
      console.error('Error when trying to load next page with link', {
        nextPageLink,
        error,
      });
    }
  };
  return (
    <Page className="vehicle-list-tab-content">
      <PageHeading heading="Vehicles" />
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
        }}
      >
        {status && (
          <Notification
            isAutoClosing={true}
            onClose={() => {
              setStatus(null);
            }}
            severity={status ? (status.severity as AlertColor) : 'success'}
          >
            {status.message}
          </Notification>
        )}
        {isLoading && <Loader loading={isLoading}></Loader>}
      </Grid>

      {vehicleList.map((vehicle: Vehicle) => (
        <VehicleCard
          data={vehicle}
          key={vehicle.VehicleGuid}
          handleEdit={setVehicleToEdit}
        />
      ))}

      {!!nextPageLink && (
        <Button
          style={{
            fontSize: '12px',
            padding: '10px',
            border: '1px solid black',
            flexBasis: '50%',
            margin: 'auto',
          }}
          onClick={loadNextPage}
        >
          {t('Load more vehicles')}
        </Button>
      )}

      {!!addVehicleLink && (
        <>
          <Button onClick={() => setShowAddModal(true)} type="submit">
            {t('+ Add Vehicle')}
          </Button>
          <AddVehicleModal
            link={addVehicleLink}
            isOpened={showAddModal}
            handleClose={() => setShowAddModal(false)}
            callback={getInitialVehiclesFromApi}
            status={setStatus}
          />
        </>
      )}

      {!!vehicleToEdit && (
        <EditVehicleModal
          data={vehicleToEdit}
          isOpen={!!vehicleToEdit}
          handleClose={() => setVehicleToEdit(undefined)}
          callback={getInitialVehiclesFromApi}
          status={setStatus}
        />
      )}
    </Page>
  );
};

export default VehicleList;
