import { FC, useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import { Controller, useForm } from 'react-hook-form';

import { DeleteOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Button, Form, InputText, Loader, PageHeading } from 'components';
import { Vehicle } from '../types';
import { axiosDelete, axiosGet, axiosPut } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { t } from 'i18next';
import { StatusType } from 'pages/request-reset-password/RequestPasswordResetPage';
import ConfirmationModal from 'components/modal/confirmation';

type Props = {
  data: Vehicle;
  isOpen: boolean;
  handleClose: () => void;
  callback: () => void;
  status: (status: StatusType) => void;
};

const EditVehicleModal: FC<Props> = ({
  data,
  isOpen,
  handleClose,
  callback,
  status: setStatus,
}) => {
  const selfLink = getApiHrefByRel(data?.Links ?? [], 'self');
  const [deleteLink, setDeleteLink] = useState<undefined | string>();
  const [descriptionLink, setDescriptionLink] = useState<undefined | string>();

  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleDeleteConfirm, setIsVisibleDeleteConfirm] = useState(false);

  const { control, handleSubmit, setValue } = useForm<Vehicle>({
    defaultValues: data,
  });

  useEffect(() => {
    if (selfLink) {
      axiosGet<Vehicle>(selfLink).then((response) => {
        const thisDeleteLink = getApiHrefByRel(
          response?.data?.Links ?? [],
          'delete',
        );
        if (thisDeleteLink) {
          setDeleteLink(thisDeleteLink);
        }
        const thisDescriptionLink = getApiHrefByRel(
          response?.data?.Links ?? [],
          'description',
        );
        if (thisDeleteLink) {
          setDescriptionLink(thisDescriptionLink);
        }
      });
    }
  }, [selfLink]);

  useEffect(() => {
    setValue('VehicleGuid', data.VehicleGuid);
    setValue('Description', data.Description);
    setValue('LicensePlate', data.LicensePlate);
    setValue('Links', data.Links);
  }, [data]);

  const onSubmit = async (data: Vehicle) => {
    try {
      setIsLoading(true);
      if (!descriptionLink) {
        throw Error(
          'Cannot update description because there is no description link',
        );
      }
      await axiosPut(descriptionLink, {
        Description: data.Description,
      });

      setStatus({
        severity: 'success',
        message: t('Vehicle updated successfully'),
      });
      callback();
    } catch (error) {
      setStatus({
        severity: 'error',
        message: t('Error while updating vehicle'),
      });
    } finally {
      handleClose();
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsVisibleDeleteConfirm(true);
  };

  const deleteVehicle = () => {
    console.log('deleting vehicle');
    if (!deleteLink) {
      console.error('Cannot delete vehicle because deleteLink was emtpy');
      return;
    }
    axiosDelete(deleteLink)
      .then(() => {
        setStatus({
          severity: 'success',
          message: t('Vehicle deleted successfully'),
        });
      })
      .catch(() => {
        setStatus({
          severity: 'error',
          message: t('Could not delete the vehicle'),
        });
      })
      .finally(() => {
        setIsVisibleDeleteConfirm(false);
        callback();
        handleClose();
      });
  };

  return (
    <Modal isVisible={isOpen} onClose={handleClose}>
      <Grid
        justifyContent={'center'}
        alignItems={'center'}
        display={'flex'}
        container
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <PageHeading heading="Edit Vehicle" />
        </Grid>
      </Grid>
      <Form onSubmit={handleSubmit(onSubmit)} className="add-vehicle-form">
        <Controller
          name="LicensePlate"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label="License plate"
              type="text"
              readOnly={true}
              control={control}
              name="LicensePlate"
              required
              rules={{
                required: t('Please enter License plate'),
              }}
            />
          )}
        />
        <Controller
          name="Description"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label="Description"
              type="text"
              control={control}
              required
              name="Description"
              rules={{
                required: t('Please enter description'),
                maxLength: {
                  value: 15,
                  message: t('Description cannot exceed 15 characters'),
                },
              }}
            />
          )}
        />
        <Grid>
          <Button
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            style={{ marginRight: '5%', width: '70%', fontSize: '24px' }}
          >
            {isLoading ? <Loader loading={isLoading}></Loader> : t('Submit')}
          </Button>

          {!!deleteLink && (
            <>
              <Button
                loading={isLoading}
                disabled={isLoading}
                onClick={handleDeleteClick}
                style={{
                  width: '25%',
                  height: '65px',
                }}
              >
                <DeleteOutlined style={{ height: '24px' }} />
              </Button>
              <ConfirmationModal
                isVisible={isVisibleDeleteConfirm}
                onConfirm={deleteVehicle}
                onCancel={() => setIsVisibleDeleteConfirm(false)}
              >
                <>
                  {t(
                    'Are you sure you want to delete the vehicle with license plate',
                  )}
                  <b> {data.LicensePlate}</b>?
                </>
              </ConfirmationModal>
            </>
          )}
        </Grid>
      </Form>
    </Modal>
  );
};

export default EditVehicleModal;
